import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import axios, { AxiosResponse } from 'axios';
import { match } from 'react-router-dom';

import {VolumetricModelComponent, Loader, Toast, Button} from '../../components';

import { IProductObject, IStartModelPosition } from "../../types/products";

import './EditVolumetricModelPositionPage.css';

interface VolumetricModelPageProps {
  match: match<{productId: string}>
}

const EditVolumetricModelPositionPage = ({ match: { params: { productId } } }: VolumetricModelPageProps): ReactElement => {
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [product, setProductState] = useState<IProductObject>({} as IProductObject);
  const [error, setError] = useState<string>('');
  const [toast, setToastState] = useState<string>('');

  const modelRef = useRef();

  useEffect(() => {
    setLoadingState(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}products/${productId}/`)
      .then((response: AxiosResponse<IProductObject>) => {
        setProductState(response.data);
        setLoadingState(false);
      })
      .catch(() => {
        setLoadingState(false);
        setError('Такого товара нет в базе данных');
      })
  }, [productId]);

  useEffect(() => {
    setTimeout(() => setToastState(''), 2500);
  }, [toast]);

  useEffect(() => {
    document.title = `${product.name} - 3D Модель - редактирование начального положения`;
  }, [product]);

  const save3DModelStartPosition = (startPosition: IStartModelPosition) => {
    if (product.volumetric_model) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}volumetric_model/${product.volumetric_model.id}/change_start_position/`,
          { start_position: startPosition },
        )
        .then(() => {
          setToastState('Начальное положение модели успешно изменено');
        })
        .catch(() => {
          setToastState('При попытке изменения начального положения модели произошла ошибка');
        })
    } else {
      setError('Для данного товара 3D модель не найдена');
    }
  };

  const handleClick = () => {
    // @ts-ignore
    const position: { x: number, y: number, z: number } = modelRef.current?.getPosition();

    save3DModelStartPosition({ X: position.x, Y: position.y, Z: position.z });
  }

  const getToastMessage = () => {
    if (toast) {
      return (
        <Toast toast={toast} />
      )
    }
    return null;
  };

  return (
    <div className="main-page-wrapper">
      {getToastMessage()}
      {Object.keys(product).length
        ? product.volumetric_model
          ? (
            <VolumetricModelComponent
              model={product.volumetric_model}
              parentRef={modelRef}
            />
          )
          : <div className="warning-message">3D-модель не представлена в базе</div>
        : isLoading
          ? <Loader />
          : <div className="warning-message">{error}</div>}

      <div className="controls-row">
        <Button
          type="button"
          onClick={handleClick}
        >
          Установить начальное положение
        </Button>
      </div>
    </div>
  );
}

export { EditVolumetricModelPositionPage };

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { VolumetricModelPage } from './pages/VolumetricModelPage';
import { EditVolumetricModelPositionPage } from './pages/EditVolumetricModelPositionPage';
import { EmptyPage } from './pages/EmptyPage';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/volumetric_model/:productId(\d+)"
          component={VolumetricModelPage}
        />
        <Route
          exact
          path="/volumetric_model/:productId(\d+)/position"
          component={EditVolumetricModelPositionPage}
        />
        <Route
          path="*"
          component={EmptyPage}
        />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

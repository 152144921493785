import React, { Component, MouseEvent, ReactElement } from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { I3DModelObject } from "../../types/products";

import "./VolumetricModelComponent.css";

interface VolumetricModelComponentProps {
  model: I3DModelObject
  parentRef?: any

  onClick?(event: MouseEvent): void
}

export class VolumetricModelComponent extends Component<VolumetricModelComponentProps> {
  mount: any;

  componentDidMount(): void {
    const { model: { src, start_position }, onClick, parentRef } = this.props;
    const { parentNode } = this.mount;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xFFFFFF);

    const camera = new THREE.PerspectiveCamera(60, parentNode.clientWidth / parentNode.clientHeight);

    if (parentRef) {
      const getPosition = () => {
        return camera.position;
      }

      parentRef.current = { getPosition };
    }

    if (start_position?.X) {
      camera.position.x = start_position.X;
    }

    camera.position.y = start_position?.Y || 65;
    camera.position.z = start_position?.Z || 100 / (parentNode.clientWidth / parentNode.clientHeight);
    camera.lookAt(new THREE.Vector3(0, 0, 0));

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(parentNode.clientWidth, parentNode.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    this.mount.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set(0, 0, 0);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = true;

    const light = new THREE.HemisphereLight(0xCCEEFF, 0xAAAAAA, 0.85);
    light.position.set(25, 50, 125);
    scene.add(light);

    const loader = new STLLoader();
    const center = new THREE.Vector3();

    loader.load(src, (geometry: THREE.BufferGeometry | undefined) => {
      const material = new THREE.MeshStandardMaterial({
        color: 'grey',
        emissive: 0.15,
        roughness: 0.25,
        metalness: 0,
      });
      const model = new THREE.Mesh(geometry, material);

      const boundingBox = new THREE.Box3().setFromObject(model);
      boundingBox.getCenter(center);

      model.position.set(0 - center.x, 0 - center.y, 0 - center.z);
      scene.add(model);
    });

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2);
    directionalLight.position.set(0 - center.x, 0 - center.y, 50);
    camera.add(directionalLight);

    scene.add(camera);

    const animate = () => {
      controls.enabled = !onClick;
      controls.update();
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    window.addEventListener('resize', () => {
      if (this.mount) {
        renderer.setSize(parentNode.clientWidth, parentNode.clientHeight);

        camera.aspect = (parentNode.clientWidth / parentNode.clientHeight);
        camera.position.z = 100 / (parentNode.clientWidth / parentNode.clientHeight);
        camera.updateProjectionMatrix();
      }
    }, { passive: true });

    animate();
  }

  render(): ReactElement {
    const { onClick } = this.props;

    return (
      <div
        role="figure"
        className="model-wrapper"
        onClick={onClick}
      >
        <div
          ref={(ref) => {
            this.mount = ref;
          }}
        />
      </div>
    );
  }
}

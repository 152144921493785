import React from 'react';

import './Button.css';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  children?: any

  onClick?(event: React.MouseEvent<HTMLButtonElement>): void
}

export const Button = ({
  onClick, children, disabled, type,
}: ButtonProps) => (
  <button
    type={type || 'button'}
    onClick={onClick}
    className="button"
    disabled={Boolean(disabled)}
  >
    <div className="inner">
      {children}
    </div>
  </button>
);

import React, { ReactElement, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { match } from 'react-router-dom';

import { VolumetricModelComponent, Loader } from '../../components';

import { IProductObject } from "../../types/products";

import './VolumetricModelPage.css';

interface VolumetricModelPageProps {
  match: match<{productId: string}>
}

const VolumetricModelPage = ({ match: { params: { productId } } }: VolumetricModelPageProps): ReactElement => {
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [product, setProductState] = useState<IProductObject>({} as IProductObject);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setLoadingState(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}products/${productId}/`)
      .then((response: AxiosResponse<IProductObject>) => {
        setProductState(response.data);
        setLoadingState(false);
      })
      .catch(() => {
        setLoadingState(false);
        setError('Такого товара нет в базе данных');
      })
  }, [productId]);

  useEffect(() => {
    document.title = `${product.name} - 3D Модель`;
  }, [product]);

  return (
    <div className="main-page-wrapper">
      {Object.keys(product).length
        ? product.volumetric_model
          ? <VolumetricModelComponent model={product.volumetric_model} />
          : <div className="warning-message">3D-модель не представлена в базе</div>
        : isLoading
          ? <Loader />
          : <div className="warning-message">{error}</div>}
    </div>
  );
}

export { VolumetricModelPage };

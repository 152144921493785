import React, { ReactElement } from 'react';

import './Toast.css';

interface ToastProps {
  toast: string
}

export const Toast = ({ toast }: ToastProps): ReactElement => (
  <div className="toast">
    <span>{toast}</span>
  </div>
);

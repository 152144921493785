import React, { ReactElement } from 'react';

import './EmptyPage.css';

const EmptyPage = (): ReactElement => (
  <div className="empty-page-wrapper">
    404, Страница не найдена
  </div>
);

export { EmptyPage };
